import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Helper } from 'app/common/helper';
import { SaveRouteListStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { BusStopService } from 'app/service/bus-stop.service';
import { CommonService } from 'app/service/common.service';
import { AppState } from 'app/store/app.state';
import { Subscription } from 'rxjs';
import { MODULE_NAME, FIELD_COMPONENT, Constant, ScreenFunctionId } from '../../config/constants';
import { DialogChangeLabelComponent } from '../../dialog/dialog-change-label/dialog-change-label.component';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { DialogRouteLabelManagerComponent } from '../../dialog/dialog-route-label-manager/dialog-route-label-manager.component';
import { BusStop } from '../../model/entity/bus-stop';
import { IndexWord } from '../../model/entity/index-word';
import { Route } from '../../model/entity/route';
import { DataService } from '../../service/data.service';
import { DialogService } from '../../service/dialog.service';
import { MenuActionService } from '../../service/menu-action.service';
import { RouteService } from '../../service/route.service';
@Component({
  selector: 'app-route-list-editor',
  templateUrl: './route-list-editor.component.html',
  styleUrls: ['./route-list-editor.component.scss']
})
export class RouteListEditorComponent implements OnInit, OnDestroy {
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * ElementRef suffix
   */
  @ViewChild('suffix') private elementRefSuffix: ElementRef;
  /**
   * ElementRef routeNo
   */
  @ViewChild('routeNo') private elementRefRouteNo: ElementRef;
  /**
   * ElementRef routeName
   */
  @ViewChild('routeName') private elementRefRouteName: ElementRef;

  @ViewChild('selectElement', { static: false })
  selectElement: ElementRef;

  /**
   * true if route selected
   */
  isShowDetail: boolean;
  /**
   * route list
   */
  routes: Array<Route>;
  /**
   * true if all route are checked, false if there is at least 1 route unchecked
   */
  isCheckedAll: boolean;
  /**
   * route is selected
   */
  routeSelected: Route;
  /**
   * bus stop is selected
   */
  busStopSelected: BusStop;
  /**
   * subcription array to add event subcribe on screen
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * false if click button add to disable the plus button
   */
  isAddRoute: boolean;
  /**
   * route no edit
   */
  routeNoEdit: string;
  /**
   * suffix edit
   */
  routeSuffixEdit: string;
  /**
   * route name edit
   */
  routeNameEdit: string;
  /**
   * true allow drop, false not allow drop
   */
  isAbleDrop: boolean = true;
  /**
   * project to route list
   */
  projectToRouteList = Constant.EMPTY;
  /**
   * projectId
   */
  projectId: Number;
  /**
   * true if change data
   */
  isChangedData: boolean;
  /**
   * true when drag from master list
   */
  isDragFromMasterList: boolean;
  /**
   * true when close dialog label manager
   */
  isCloseLabelManager: boolean;
  /**
   * state of component
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    routes: Route[];
    routeSelected: Route;
    busStopSelected: BusStop;
    routeNoEdit: string;
    routeSuffixEdit: string;
    routeNameEdit: string;
    isAbleDrop: boolean;
    isShowDetail: boolean;
    isCheckedAll: boolean;
    isAddRoute: boolean;
    isChangedData: boolean;
    busStopsOfRoute: Array<BusStop>;
  };
  /**
   * constructor
   * @param routeService RouteService
   * @param busStopService BusStopService
   * @param menuActionService MenuActionService
   * @param dragDropService DragDropService
   */
  constructor(
    private routeService: RouteService,
    private busStopService: BusStopService,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef,
    public readonly store: Store<AppState>,
    private commonService: CommonService
  ) {
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.RouteListEditorComponent]) {
          this.saveRoute();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.RouteListEditorComponent]) {
          this.addRoute();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.RouteListEditorComponent]) {
          this.editRoute();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.RouteListEditorComponent]) {
          this.duplicateRoute();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.RouteListEditorComponent]) {
          if (this.busStopSelected) {
            this.deleteBusStop();
          } else {
            this.deleteRoute();
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionChangeLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.RouteListEditorComponent]) {
          this.changeLabelRoute();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionManageLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.RouteListEditorComponent]) {
          this.dialogLabelManager();
        }
      })
    );
    // open with when project
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.ROUTE_LIST_NAME) {
        this.projectToRouteList = data[0];
        this.selectRoute(data[1]);
      }
    });
    this.dataService.currentData.subscribe(data => {
      if (data[0] == 'mouseOver') {
        this.isDragFromMasterList = data[1];
      }
    });
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.routeListEditorState?.stateOfComponent.isChangeLayout,
            routes: componentState.routeListEditorState?.stateOfComponent.routes,
            routeSelected: componentState.routeListEditorState?.stateOfComponent.routeSelected,
            busStopSelected: componentState.routeListEditorState?.stateOfComponent.busStopSelected,
            routeNoEdit: componentState.routeListEditorState?.stateOfComponent.routeNoEdit,
            routeSuffixEdit: componentState.routeListEditorState?.stateOfComponent.routeSuffixEdit,
            routeNameEdit: componentState.routeListEditorState?.stateOfComponent.routeNameEdit,
            isAbleDrop: componentState.routeListEditorState?.stateOfComponent.isAbleDrop,
            isShowDetail: componentState.routeListEditorState?.stateOfComponent.isShowDetail,
            isCheckedAll: componentState.routeListEditorState?.stateOfComponent.isCheckedAll,
            isAddRoute: componentState.routeListEditorState?.stateOfComponent.isAddRoute,
            isChangedData: componentState.routeListEditorState?.stateOfComponent.isChangedData,
            busStopsOfRoute: componentState.routeListEditorState?.stateOfComponent.busStopsOfRoute
          };
        })
    );
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.projectId = this.commonService.getCommonObject().projectId;
    if (!this.stateOfComponent?.isChangeLayout) {
      this.fetchRoutes();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveRouteListStateAction({
        isChangeLayout: true,
        routes: this.routes,
        routeSelected: this.routeSelected,
        busStopSelected: this.busStopSelected,
        routeNoEdit: this.routeNoEdit,
        routeSuffixEdit: this.routeSuffixEdit,
        routeNameEdit: this.routeNameEdit,
        isAbleDrop: this.isAbleDrop,
        isShowDetail: this.isShowDetail,
        isCheckedAll: this.isCheckedAll,
        isAddRoute: this.isAddRoute,
        isChangedData: this.isChangedData,
        busStopsOfRoute: this.routeSelected?.busStops
      })
    );
  }

  /**
   * handle after change layout
   */
  private handleAfterChangeLayout(): void {
    this.routes = this.stateOfComponent?.routes;
    this.routeSelected = this.stateOfComponent?.routeSelected;
    this.busStopSelected = this.stateOfComponent?.busStopSelected;
    this.routeNoEdit = this.stateOfComponent?.routeNoEdit;
    this.routeSuffixEdit = this.stateOfComponent?.routeSuffixEdit;
    this.routeNameEdit = this.stateOfComponent?.routeNameEdit;
    this.isAbleDrop = this.stateOfComponent?.isAbleDrop;
    this.isShowDetail = this.stateOfComponent?.isShowDetail;
    this.isCheckedAll = this.stateOfComponent?.isCheckedAll;
    this.isAddRoute = this.stateOfComponent?.isAddRoute;
    this.isChangedData = this.stateOfComponent?.isChangedData;
    this.routeSelected.busStops = this.stateOfComponent?.busStopsOfRoute;
  }

  /**
   * add route
   */
  addRoute(): void {
    if (this.routeSelected && this.routeSelected.isEdit) {
      return;
    }
    this.routeNoEdit = Constant.EMPTY;
    this.routeSuffixEdit = Constant.EMPTY;
    this.routeNameEdit = Constant.EMPTY;
    this.isAddRoute = true;
    this.isChangedData = true;
    this.routes.push(new Route(this.routeNoEdit, this.routeSuffixEdit, this.routeNameEdit, this.projectId));
    this.routeSelected = this.routes[this.routes.length - 1];
    this.routeSelected.isEdit = true;
    this.isShowDetail = false;
    this.changeDetectorRef.detectChanges();
    this.selectElement.nativeElement.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
  }

  /**
   * edit route
   */
  editRoute(): void {
    if (!this.routeSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Please select a route.'
        }
      });
      return;
    }
    if (this.routeSelected && this.routeSelected?.isEdit) {
      return;
    }
    this.isAddRoute = true;
    this.isChangedData = true;
    this.routeSelected.isEdit = true;
  }

  /**
   * select route
   * @param route Route
   */
  selectRoute(route: Route): void {
    if (this.isAddRoute || !route || this.routeSelected?.id == route.id) {
      return;
    }
    this.busStopSelected = undefined;
    this.routeSelected = route;
    this.handleRouteInformation(this.routeSelected);
    this.isShowDetail = this.routeSelected.id && !this.routeSelected.isEdit;
    // get bus stop of route
    this.busStopService.getBusStopsForRouteListEditorComponentByRouteId(this.routeSelected.id).subscribe(
      busStopsData => {
        this.routeSelected.busStops = Helper.convertDataBusStopsData(busStopsData);
        this.handleIndexWordsData(this.routeSelected.busStops);
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * handle route information
   * @param route Route
   */
  handleRouteInformation(route: Route): void {
    this.routeNoEdit = route.routeNo;
    this.routeSuffixEdit = route.suffix;
    this.routeNameEdit = route.name;
  }

  /**
   * handle indexwords data
   * @param busStops list bus stop of route
   */
  handleIndexWordsData(busStops: Array<BusStop>): void {
    let maxIndexword = 0;
    busStops.forEach(busStopData => {
      // sort by index word group
      busStopData.indexWords = busStopData.indexWords.sort((indexWord1, indexWord2) => {
        return <number>indexWord1.groupId - <number>indexWord2.groupId;
      });
      // get index word unique id
      let indexWordGroupIds = busStopData.indexWords
        .map(indexWorData => indexWorData.groupId)
        .filter((value, index, arr) => arr.indexOf(value) === index);
      // handle image of index word
      busStopData['imageIndexWords'] = new Array<IndexWord>();
      indexWordGroupIds.forEach(indexWordGroupId => {
        // remove the end of index word hasn't media
        let indexWordsDisplay = this.handleDataImageIndexWord(
          busStopData.indexWords.filter(indexWordData => indexWordData.groupId == indexWordGroupId)
        );
        if (indexWordsDisplay) {
          // push valid indexword to the list
          indexWordsDisplay.forEach(indexWordData => {
            busStopData['imageIndexWords'].push(indexWordData ? indexWordData : new IndexWord());
          });
        }
      });
      // get maxIndex value
      maxIndexword = maxIndexword > busStopData['imageIndexWords'].length ? maxIndexword : busStopData['imageIndexWords'].length;
      // handle text of index word
      busStopData['textIndexWords'] = busStopData.indexWords.filter(indexWord => indexWord.text);
    });
    // fill index word to the list by maxIndexword
    busStops.forEach(busStopData => {
      if (busStopData['imageIndexWords'].length < maxIndexword) {
        let maxLength = maxIndexword - busStopData['imageIndexWords'].length;
        for (let i = 0; i < maxLength; i++) {
          busStopData['imageIndexWords'].push(new IndexWord());
        }
      }
    });
  }

  /**
   * handel data image index word
   * @param indexWords list IndexWord
   */
  handleDataImageIndexWord(indexWords: Array<IndexWord>): Array<IndexWord> {
    for (let i = indexWords.length - 1; i >= 0; i--) {
      if (!indexWords[i].media) {
        indexWords.splice(i, 1);
        continue;
      }
      return indexWords;
    }
  }

  /**
   * fetch routes
   */
  private fetchRoutes(): void {
    this.routeService.getRoutesForRouteListEditorComponentByProjectId(this.projectId).subscribe(
      routesData => {
        this.routes = routesData.map(routeDto => Helper.convertDataRoute(routeDto));
        if (this.routes.length > 0) {
          if (this.isCloseLabelManager) {
            let index = this.routes.findIndex(routeData => routeData.id == this.routeSelected.id);
            if (index != -1) {
              this.routes[index].busStops = this.routeSelected.busStops;
              this.routeSelected = this.routes[index];
            }
            this.isCloseLabelManager = false;
          } else {
            this.selectRoute(this.routes[0]);
          }
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * check or uncheck a route
   * @param index index of check-changed route
   * @param e
   */
  changeChecked(index: number, e): void {
    e.stopPropagation();
    this.routes[index].isChecked = !this.routes[index].isChecked;
    this.isCheckedAll = this.routes.every(route => route.isChecked);
  }

  /**
   * check or uncheck all route
   */
  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.routes.forEach(route => (route.isChecked = this.isCheckedAll));
  }

  /**
   * cancel save route when add or edit
   */
  cancelSaveRoute(): void {
    if (this.routeSelected && !this.routeSelected.isEdit) {
      return;
    }
    this.isAddRoute = false;
    this.isChangedData = false;
    this.routeSelected.isEdit = false;
    if (!this.routeSelected.id) {
      this.routes.pop();
      if (this.routes.length > 0) {
        this.selectRoute(this.routes[0]);
      } else {
        this.routeSelected = undefined;
      }
    } else {
      this.selectRoute(this.routeSelected);
    }
    this.isCheckedAll = this.routes.length > 0 && this.routes.every(route => route.isChecked);
  }

  /**
   * save cancel to exit
   */
  saveRoute(): void {
    if (this.routeSelected && !this.routeSelected.isEdit) {
      return;
    }
    // validate route no
    let routeNo = this.routeNoEdit.trim();
    if (routeNo.length < Constant.MIN_NO_LENGTH) {
      this.elementRefRouteNo.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Route No. cannot be empty.'
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }
    if (routeNo.length > Constant.MAX_NO_LENGTH) {
      this.elementRefRouteNo.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Route No. must contain no more than 5 characters.'
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }

    // validate route suffix
    let suffix = this.routeSuffixEdit.trim();
    if (suffix.length > Constant.MAX_SUFFIX_LENGTH) {
      this.elementRefSuffix.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Suffix must contain no more than 3 characters.'
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }

    // validate route route name
    let routeName = this.routeNameEdit.trim();
    if (routeName.length < Constant.MIN_NAME_LENGTH) {
      this.elementRefRouteName.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Route Name cannot be empty.'
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }
    if (routeName.length > Constant.MAX_NAME_LENGTH) {
      this.elementRefRouteName.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Route Name must contain no more than 48 characters.'
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }
    // validate duplicate route
    let routesIdExisted: Array<string> = this.routes
      .filter(route => route !== this.routeSelected)
      .map(route => `${route.routeNo}-${route.suffix}`);
    let routeId = `${routeNo}-${suffix}`;
    if (routesIdExisted.includes(routeId)) {
      this.elementRefRouteNo.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Duplicated route.'
        }
      });
      this.saveDataSuccess.emit(false);
      return;
    }
    // asign value
    this.routeSelected.isEdit = false;
    this.routeSelected.name = routeName;
    this.routeSelected.routeNo = routeNo;
    this.routeSelected.suffix = suffix;
    // Update route
    if (this.routeSelected.id) {
      this.routeService.updateRoute(Helper.convertDataRouteOriginal(this.routeSelected)).subscribe(
        routeData => {
          let routeEdited = Helper.convertDataRoute(routeData);
          routeEdited.isChecked = this.routeSelected.isChecked;
          routeEdited.busStops = this.routeSelected.busStops;
          let routeIndex = this.routes.findIndex(route => route.id == routeEdited.id);
          if (routeIndex == -1) {
            return;
          }
          this.routes[routeIndex] = routeEdited;
          this.routeSelected = this.routes[routeIndex];
          this.handleRouteInformation(this.routes[routeIndex]);
          this.isAddRoute = false;
          this.isChangedData = false;
          this.saveDataSuccess.emit(true);
        },
        error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
          this.saveDataSuccess.emit(false);
        }
      );
    } else {
      if (!this.routeSelected.busStops) {
        // Add route
        this.routeService.addRoute(Helper.convertDataRouteForward(this.routeSelected)).subscribe(
          routeData => {
            this.routes[this.routes.length - 1] = Helper.convertDataRoute(routeData);
            this.isAddRoute = false;
            this.isChangedData = false;
            this.selectRoute(this.routes[this.routes.length - 1]);
            this.isCheckedAll = this.routes.every(route => route.isChecked);
            this.saveDataSuccess.emit(true);
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
            this.saveDataSuccess.emit(false);
          }
        );
      } else {
        // Duplicate route
        let duplicatedRoute = this.routeSelected;
        if (duplicatedRoute?.busStops?.length > 0) {
          duplicatedRoute.busStops.forEach(busStopData => (busStopData.routeBustopId = null));
        }
        this.routeService.duplicateRoute(Helper.convertDataRouteForward(duplicatedRoute)).subscribe(
          routeData => {
            this.routes[this.routes.length - 1] = Helper.convertDataRoute(routeData);
            this.routes[this.routes.length - 1].busStops = this.routeSelected.busStops;
            this.isAddRoute = false;
            this.isChangedData = false;
            this.selectRoute(this.routes[this.routes.length - 1]);
            this.isCheckedAll = this.routes.every(route => route.isChecked);
            this.saveDataSuccess.emit(true);
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
            this.saveDataSuccess.emit(false);
          }
        );
      }
    }
  }

  /**
   * delete route
   */
  deleteRoute(): void {
    if (this.routeSelected.isEdit) {
      return;
    }
    let checkedRoutes = this.routes.filter(route => route.isChecked);
    if (checkedRoutes.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a route.' } });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: checkedRoutes.length > 1 ? 'Do you want to delete checked routes?' : 'Do you want to delete the selected route?',
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          this.routeService.deleteRoutes(checkedRoutes).subscribe(
            () => {
              checkedRoutes.forEach(route => {
                let routeIndex = this.routes.findIndex(routeData => routeData.id == route.id);
                if (routeIndex != -1) {
                  this.routes.splice(routeIndex, 1);
                }
              });
              if (this.routes.length > 0) {
                this.selectRoute(this.routes[0]);
              } else {
                this.routeSelected = undefined;
              }
              this.isCheckedAll = false;
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
              return;
            }
          );
        }
      }
    );
  }

  /**
   * drop Route
   * @param event CdkDragDrop
   */
  drop(event: CdkDragDrop<BusStop[]>): void {
    if (!this.isAbleDrop) {
      return;
    }
    event.previousContainer.data[event.previousIndex] = Object.assign({}, event.previousContainer.data[event.previousIndex]);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.routeSelected.busStops = event.container.data;
    } else {
      copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      event.container.data[event.currentIndex] = Helper.convertDataBusStop(event.container.data[event.currentIndex]);
      this.routeSelected.busStops = event.container.data;
      this.handleIndexWordsData(this.routeSelected.busStops);
    }
    this.routeSelected.busStops.forEach((busStopData, index) => (busStopData.orderNo = index));
    this.routeService.updateBusStopsOfRoute(this.routeSelected).subscribe(
      routeBusStops => {
        this.routeSelected.busStops.map((busStopData, index) => (busStopData.routeBustopId = routeBusStops[index].id));
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * select bus stop
   * @param busStop bus stop is selected when click on screen
   * @param index
   */
  selectBusStop(busStop): void {
    this.busStopSelected = busStop;
  }

  /**
   * delete bus Stop
   */
  deleteBusStop(): void {
    if (this.routeSelected.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete this bus stop?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          this.routeService.deleteBusStopOfRoute(this.routeSelected.id, this.busStopSelected).subscribe(
            () => {
              let indexSelectedBusStop = this.routeSelected.busStops.findIndex(
                busStopData => this.busStopSelected.routeBustopId == busStopData.routeBustopId
              );
              if (indexSelectedBusStop != -1) {
                this.routeSelected.busStops.splice(indexSelectedBusStop, 1);
                if (this.routeSelected.busStops.length > 0) {
                  this.routeSelected.busStops.forEach((busStopData, index) => (busStopData.orderNo = index));
                  this.selectBusStop(this.routeSelected.busStops[0]);
                  this.handleIndexWordsData(this.routeSelected.busStops);
                } else {
                  this.busStopSelected = undefined;
                }
              }
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        }
      }
    );
  }

  /**
   * show dialog change label
   */
  changeLabelRoute(): void {
    if (this.isAddRoute) {
      return;
    }
    let checkedRoutes = this.routes.filter(route => route.isChecked);
    if (checkedRoutes.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a route.' } });
      return;
    }
    let routeCheckedFirst = checkedRoutes[0];
    let labelCommon =
      checkedRoutes.length == 1
        ? routeCheckedFirst.label
        : checkedRoutes.every(route => route?.label?.id == routeCheckedFirst?.label?.id)
        ? routeCheckedFirst.label
        : null;
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogChangeLabelComponent,
      { data: { labelCommon: labelCommon, title: 'Select Route Label', functionId: ScreenFunctionId.ROUTE_LIST } },
      result => {
        this.isChangedData = false;
        if (result != '') {
          checkedRoutes.map(route => (route.isChecked = false));
          this.isCheckedAll = false;
          let routesSaved = checkedRoutes.map(routeData => {
            routeData.label = result;
            return Helper.convertDataRouteOriginal(routeData);
          });
          this.routeService.saveLabelForRoutes(routesSaved).subscribe(
            data => {
              let routesOutput = data.map(routeData => Helper.convertDataRoute(routeData));
              this.routes.forEach(routeData => {
                let index = routesOutput.findIndex(route => route.id == routeData.id);
                if (index != -1) {
                  routeData = routesOutput[index];
                }
              });
              this.selectRoute(this.routeSelected);
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        }
      }
    );
  }

  /**
   * show dialog bus stop label manager
   */
  dialogLabelManager(): void {
    if (this.isAddRoute) {
      return;
    }
    let checkedRoutes = this.routes.filter(route => route.isChecked);
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogRouteLabelManagerComponent,
      { autoFocus: false, data: { title: 'Route Label', functionId: ScreenFunctionId.ROUTE_LIST } },
      result => {
        this.isChangedData = false;
        if (result) {
          if (checkedRoutes) {
            checkedRoutes.map(route => (route.isChecked = false));
            this.isCheckedAll = false;
          }
          this.isCloseLabelManager = true;
          this.fetchRoutes();
        }
      }
    );
  }

  /**
   * duplicate selected route
   */
  duplicateRoute(): void {
    if (!this.routeSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Please select a route.'
        }
      });
      return;
    }
    if (this.routeSelected && this.routeSelected?.isEdit) {
      return;
    }
    this.routeNoEdit = this.routeSelected.routeNo;
    this.routeSuffixEdit = this.routeSelected.suffix;
    this.routeNameEdit = this.routeSelected.name;
    let dulicatedRoute = new Route(this.routeNoEdit, this.routeSuffixEdit, this.routeNameEdit, this.projectId);
    dulicatedRoute.busStops = this.routeSelected.busStops;
    dulicatedRoute.label = this.routeSelected?.label;
    this.routes.push(dulicatedRoute);
    this.routeSelected = dulicatedRoute;
    this.isAddRoute = true;
    this.isChangedData = true;
    this.routeSelected.isEdit = true;
    this.isShowDetail = false;
  }
}
