import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Directive({
  selector: '[appLazyScroll]'
})
export class LazyScrollDirective implements AfterViewInit, OnChanges {
  @Input()
  scrollDistance = 30;
  @Input()
  reloadDirective = false;
  @Input()
  scrollContainer = window;
  @Input()
  stopLazyScroll: Subject<any>;

  @Output()
  onBottomReached: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onTopReached: EventEmitter<any> = new EventEmitter<any>();

  nativeEl: HTMLElement;
  container;
  subscription: Subscription;
  scroll$;

  constructor(elRef: ElementRef, private cdr: ChangeDetectorRef) {
    this.nativeEl = elRef.nativeElement;
  }

  ngAfterViewInit() {
    this.evaluateContainer(this.scrollContainer);
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reloadDirective']) {
      this.evaluateContainer(this.scrollContainer);
      this.cdr.detectChanges();
    }
  }
  attachListner(ele = this.nativeEl) {
    this.scroll$ = fromEvent(ele, 'scroll');
    this.subscription = this.scroll$.pipe(takeUntil(this.stopLazyScroll)).subscribe(this.listen.bind(this));
  }

  changeContainer(newContainer) {
    if (this.container) {
      //removing the scroll event from old container
      this.subscription.unsubscribe();
    }
    this.container = newContainer;
    this.attachListner(newContainer);
  }

  evaluateContainer(newContainer) {
    let container;
    if (newContainer.nodeType === 1) {
      container = newContainer;
    } else if (typeof newContainer.append === 'function') {
      container = newContainer.pop();
    } else if (typeof newContainer === 'string') {
      container = document.querySelector(newContainer);
    } else {
      container = newContainer;
    }
    if (null === newContainer) {
      throw new Error('invalid scroll-container attribute.');
    }
    this.changeContainer(container);
  }

  height(el) {
    let height = el.offsetHeight;
    if (isNaN(height)) {
      height = el.innerHeight;
      // issue: below commented line includes both height and scrollPos
      // height = el.document.documentElement.offsetHeight;
    }
    return height;
  }

  pageYOffset(el) {
    let scrollPos = el.pageYOffset;
    if (isNaN(scrollPos)) {
      scrollPos = el.ownerDocument.defaultView.pageYOffset;
    }
    // return el.ownerDocument.defaultView.pageYOffset;
    return scrollPos;
  }

  offsetTop(element) {
    if (element.getBoundingClientRect) {
      return element.getBoundingClientRect().top + this.pageYOffset(element);
    }
    return undefined;
  }
  listen() {
    let elementBottom;
    let elementTop;
    let containerBottom;
    let containerTopOffset = 0;
    let tempTopOffset;

    let containerHeight = this.height(this.container);
    if (this.container === window) {
      // containerBottom = containerHeight;
      containerBottom = containerHeight + this.pageYOffset(this.container);
    } else {
      containerBottom = this.height(this.container);
      //       let containerTopOffset = 0;
      //       if (offsetTop(container) !== undefined) {
      //         containerTopOffset = offsetTop(container);
      //       }
    }
    tempTopOffset = this.offsetTop(this.container);
    if (tempTopOffset) {
      containerTopOffset = tempTopOffset;
    }
    elementBottom = this.offsetTop(this.nativeEl) - containerTopOffset + this.height(this.nativeEl);
    elementTop = this.offsetTop(this.nativeEl) - containerTopOffset;

    // const remaining = elementBottom - containerBottom;
    // console.log('CB -> ', containerBottom + ' ', this.scrollDistance);
    const shouldScroll = containerBottom + this.scrollDistance >= elementBottom; // check botton
    const shouldScrollTop = elementTop >= 0; // check top
    // console.log('containerBottom -> ', containerBottom);
    // console.log('scrollDistance -> ', this.scrollDistance);
    // console.log('elementBottom -> ', elementBottom);
    // console.log('containerTopOffset -> ', containerTopOffset);
    // console.log(' this.height(this.nativeEl) -> ',  this.height(this.nativeEl));
    // console.log('this.offsetTop(this.nativeEl) -> ', this.offsetTop(this.nativeEl));
    // console.log('top -> ', this.nativeEl.offsetTop);

    if (shouldScroll) {
      this.onBottomReached.emit();
    }
    if (shouldScrollTop) {
      this.onTopReached.emit();
    }
  }
}
