import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Helper } from 'app/common/helper';
import { BusStop } from 'app/model/entity/bus-stop';
import { Common } from 'app/model/entity/common';
import { PublishInfo } from 'app/model/entity/publish-info';
import { Style } from 'app/model/entity/style';
import { SaveMainStateAction, SaveProjectManagerStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { BusStopService } from 'app/service/bus-stop.service';
import { PublishInfoService } from 'app/service/publish-info.service';
import { StyleServive } from 'app/service/style.service';
import { AppState } from 'app/store/app.state';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Constant, DestinationEnum, FIELD_COMPONENT, MODULE_NAME, PUBLISH_TYPE } from '../../config/constants';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { DialogProjectManagerComponent } from '../../dialog/dialog-project-manager/dialog-project-manager.component';
import { Channel } from '../../model/entity/channel';
import { Project } from '../../model/entity/project';
import { Route } from '../../model/entity/route';
import { DataService } from '../../service/data.service';
import { DialogService } from '../../service/dialog.service';
import { MenuActionService } from '../../service/menu-action.service';
import { ProjectService } from '../../service/project.service';
import { RouteService } from '../../service/route.service';
import { SignageChannelService } from '../../service/signage-channel.service';

@Component({
  selector: 'app-project-manager',
  templateUrl: './project-manager.component.html',
  styleUrls: ['./project-manager.component.scss']
})
/**
 * Component class for Project
 */
export class ProjectManagerComponent implements OnInit, OnDestroy {
  /**
   * true if add new project
   */
  isAddNew: boolean = false;
  /**
   * true if tab destination selected
   */
  isActiveTabDestination: boolean = false;
  /**
   * true if tab bus information selected
   */
  isActiveTabBusInform: boolean = false;
  /**
   * true if tab signage selected
   */
  isActiveTabDigitalSignage: boolean = false;
  /**
   * true if tab station selected
   */
  isActiveTabStation: boolean = false;
  /**
   * true if tab station selected
   */
  isActiveTabSignageDisplay: boolean = false;
  /**
   * project list
   */
  projects: Array<Project> = new Array<Project>();
  /**
   * route list
   */
  routes: Array<Route>;
  /**
   * bus stop list
   */
  busStops: Array<BusStop>;
  /**
   * channel list
   */
  channels: Array<Channel>;
  /**
   * style list
   */
  styles: Array<Style>;
  /**
   * current opening project
   */
  projectOpened: Project;
  /**
   * selected project
   */
  projectSelected: Project;
  /**
   * true if dbclick Project
   */
  isShowDetail: boolean = false;
  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * list data publish for destination display
   */
  publishDestinations: Array<PublishInfo>;
  /**
   * list data publish for on-bus display
   */
  publishInfoBIDs: Array<PublishInfo>;
  /**
   * list data publish for signage channel display
   */
  publishInfoDSCs: Array<PublishInfo>;
  /**
   * list data publish for station display
   */
  publishInfoBSDs: Array<PublishInfo>;
  /**
   * list data publish for signage display
   */
  publishInfoGIDs: Array<PublishInfo>;
  /**
   * true if changed data
   */
  isChangedData: boolean = false;
  /**
   * true if selected all publish file
   */
  isCheckAll: boolean = false;
  /**
   * selected channel
   */
  channelSelected: Channel;
  /**
   * selected route
   */
  routeSelected: Route;
  /**
   * selected style
   */
  styleSelected: Style;
  /**
   * selected bus stop
   */
  busStopSelected: BusStop;
  /**
   * list data type of project
   */
  activedFunctionList: Array<string>;
  /**
   * true if choose tab Editing
   */
  isActiveTabEditing: boolean = true;
  /**
   * name of current tab
   */
  currentTab: string;
  /**
   * constants
   */
  readonly ON_BUS_VALUE = 'On-Bus';
  readonly DESTINATION_SIGN_VALUE = 'Destination-Sign';
  readonly DIGITAL_SIGNAGE_VALUE = 'Digital-Signage';
  readonly STATION_VALUE = 'Station';
  readonly SIGNAGE_DISPLAY_VALUE = 'Signage-Display';
  readonly CAN_OPEN_ON_BUS_DISPLAY = 'canOpenWithOnBusDisplay';
  readonly CAN_OPEN_DESTINATION_SIGN = 'canOpenWithDestinationSign';
  readonly CAN_OPEN_DIGITAL_SIGNAGE = 'canOpenWithDigitalSignage';
  readonly CAN_OPEN_STATION = 'canOpenWithStation';
  readonly CAN_OPEN_SIGNAGE_DISPLAY = 'canOpenWithSignageDisplay';
  readonly PROJECT_NAME = 'projectName';
  readonly CONFIRM_DELETE_PUBLISH_FILE = 'Do you want to delete publish file?';
  readonly MESSAGE_DELETE_SUCCESS = 'Delete successfully.';
  readonly ADD_VALUE = 'ADD';
  readonly EDIT_VALUE = 'EDIT';
  /**
   * Destination Enum
   */
  DestinationEnum = DestinationEnum;

  /**
   * state of component
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    projects: Project[];
    routes: Route[];
    busStops: BusStop[];
    channels: Channel[];
    styles: Style[];
    publishDestinations: PublishInfo[];
    publishInfoBIDs: PublishInfo[];
    publishInfoDSCs: PublishInfo[];
    publishInfoBSDs: PublishInfo[];
    publishInfoGIDs: PublishInfo[];
    projectSelected: Project;
    projectOpened: Project;
    isActiveTabDestination: boolean;
    isActiveTabBusInform: boolean;
    isActiveTabSignage: boolean;
    isActiveTabStation: boolean;
    isActiveTabSignageDisplay: boolean;
    isShowDetail: boolean;
    isChangedData: boolean;
    channelSelected: Channel;
    routeSelected: Route;
    busStopSelected: BusStop;
    styleSelected: Style;
  };
  /**
   * common object
   */
  commonObject: Common;
  /**
   * constructor
   * @param projectService ProjectService
   * @param routeService RouteService
   * @param dialogService DialogService
   * @param menuActionService MenuActionService
   * @param inMemoryService InMemoryDataService
   */
  constructor(
    private projectService: ProjectService,
    private publishInfoService: PublishInfoService,
    private routeService: RouteService,
    private busStopService: BusStopService,
    private styleService: StyleServive,
    private dialogService: DialogService,
    private menuActionService: MenuActionService,
    private dataService: DataService,
    private channelService: SignageChannelService,
    private changeDetectorRef: ChangeDetectorRef,
    public readonly store: Store<AppState>,
    private toast: ToastrService
  ) {
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ProjectManagerComponent]) {
          this.delete();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ProjectManagerComponent]) {
          this.editProject();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ProjectManagerComponent]) {
          this.addProject();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ProjectManagerComponent]) {
          this.duplicateProject();
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.projectManagerState?.stateOfComponent.isChangeLayout,
            projects: componentState.projectManagerState?.stateOfComponent.projects,
            routes: componentState.projectManagerState?.stateOfComponent.routes,
            styles: componentState.projectManagerState?.stateOfComponent.styles,
            busStops: componentState.projectManagerState?.stateOfComponent.busStops,
            channels: componentState.projectManagerState?.stateOfComponent.channels,
            publishDestinations: componentState.projectManagerState?.stateOfComponent.publishDestinations,
            publishInfoBIDs: componentState.projectManagerState?.stateOfComponent.publishInfoBIDs,
            publishInfoDSCs: componentState.projectManagerState?.stateOfComponent.publishInfoDSCs,
            publishInfoBSDs: componentState.projectManagerState?.stateOfComponent.publishInfoBSDs,
            publishInfoGIDs: componentState.projectManagerState?.stateOfComponent.publishInfoGIDs,
            projectSelected: componentState.projectManagerState?.stateOfComponent.projectSelected,
            projectOpened: componentState.projectManagerState?.stateOfComponent.projectOpened,
            isActiveTabDestination: componentState.projectManagerState?.stateOfComponent.isActiveTabDestination,
            isActiveTabBusInform: componentState.projectManagerState?.stateOfComponent.isActiveTabBusInform,
            isActiveTabSignage: componentState.projectManagerState?.stateOfComponent.isActiveTabSignage,
            isActiveTabStation: componentState.projectManagerState?.stateOfComponent.isActiveTabStation,
            isShowDetail: componentState.projectManagerState?.stateOfComponent.isShowDetail,
            isChangedData: componentState.projectManagerState?.stateOfComponent.isChangedData,
            isActiveTabSignageDisplay: componentState.projectManagerState?.stateOfComponent.isActiveTabSignageDisplay,
            channelSelected: componentState.projectManagerState?.stateOfComponent.channelSelected,
            routeSelected: componentState.projectManagerState?.stateOfComponent.routeSelected,
            busStopSelected: componentState.projectManagerState?.stateOfComponent.busStopSelected,
            styleSelected: componentState.projectManagerState?.stateOfComponent.styleSelected
          };
        })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.commonObject = componentState?.mainState?.stateOfComponent?.common ?? new Common();
        })
    );

    this.subscriptions.push(
      this.menuActionService.actionOpenWith.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ProjectManagerComponent]) {
          // Code here
        }
      })
    );
  }

  ngOnInit(): void {
    if (!this.stateOfComponent?.isChangeLayout) {
      this.fetchDataProjects();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveProjectManagerStateAction({
        isChangeLayout: true,
        projects: this.projects,
        routes: this.routes,
        busStops: this.busStops,
        channels: this.channels,
        styles: this.styles,
        publishDestinations: this.publishDestinations,
        publishInfoBIDs: this.publishInfoBIDs,
        publishInfoDSCs: this.publishInfoDSCs,
        publishInfoBSDs: this.publishInfoBSDs,
        publishInfoGIDs: this.publishInfoGIDs,
        projectSelected: this.projectSelected,
        projectOpened: this.projectOpened,
        isActiveTabDestination: this.isActiveTabDestination,
        isActiveTabBusInform: this.isActiveTabBusInform,
        isActiveTabSignage: this.isActiveTabDigitalSignage,
        isActiveTabStation: this.isActiveTabStation,
        isActiveTabSignageDisplay: this.isActiveTabSignageDisplay,
        isShowDetail: this.isShowDetail,
        isChangedData: this.isChangedData,
        channelSelected: this.channelSelected,
        routeSelected: this.routeSelected,
        busStopSelected: this.busStopSelected,
        styleSelected: this.styleSelected
      })
    );
  }

  private handleAfterChangeLayout() {
    this.projects = this.stateOfComponent?.projects;
    this.routes = this.stateOfComponent?.routes;
    this.busStops = this.stateOfComponent?.busStops;
    this.channels = this.stateOfComponent?.channels;
    this.styles = this.stateOfComponent?.styles;
    this.publishDestinations = this.stateOfComponent?.publishDestinations;
    this.publishInfoBIDs = this.stateOfComponent?.publishInfoBIDs;
    this.publishInfoDSCs = this.stateOfComponent?.publishInfoDSCs;
    this.publishInfoBSDs = this.stateOfComponent?.publishInfoBSDs;
    this.publishInfoGIDs = this.stateOfComponent?.publishInfoGIDs;
    this.projectSelected = this.stateOfComponent?.projectSelected;
    this.projectOpened = this.stateOfComponent?.projectOpened;
    this.isActiveTabDestination = this.stateOfComponent?.isActiveTabDestination;
    this.isActiveTabBusInform = this.stateOfComponent?.isActiveTabBusInform;
    this.isActiveTabDigitalSignage = this.stateOfComponent?.isActiveTabSignage;
    this.isActiveTabStation = this.stateOfComponent?.isActiveTabStation;
    this.isActiveTabSignageDisplay = this.stateOfComponent?.isActiveTabSignageDisplay;
    this.isShowDetail = this.stateOfComponent?.isShowDetail;
    this.isChangedData = this.stateOfComponent?.isChangedData;
    this.channelSelected = this.stateOfComponent?.channelSelected;
    this.routeSelected = this.stateOfComponent?.routeSelected;
    this.busStopSelected = this.stateOfComponent?.busStopSelected;
    this.styleSelected = this.stateOfComponent?.styleSelected;
  }

  /**
   * open project
   * @param {Project} project selected project
   */
  openProject(project: Project) {
    this.activedFunctionList = new Array<string>();
    this.publishInfoBIDs = new Array<PublishInfo>();
    this.publishInfoDSCs = new Array<PublishInfo>();
    this.publishDestinations = new Array<PublishInfo>();
    this.publishInfoBSDs = new Array<PublishInfo>();
    this.publishInfoGIDs = new Array<PublishInfo>();

    this.resetActiveTab();
    this.routeSelected = undefined;
    this.channelSelected = undefined;
    this.busStopSelected = undefined;
    this.styleSelected = undefined;
    this.isActiveTabEditing = true;
    this.projectOpened = project;
    // display project name in header`
    this.dataService.sendData([this.PROJECT_NAME, this.projectOpened.name]);
    this.commonObject.projectId = this.projectOpened.id;
    this.commonObject.projectName = this.projectOpened.name;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    this.isShowDetail = true;
    this.handleWhenOpenProject();
    let editing = document.getElementById('tab-editing') as HTMLElement;
    let publish = document.getElementById('tab-publish') as HTMLElement;
    if (editing && publish) {
      editing.className = 'active';
      (document.getElementById('editing') as HTMLElement).className = 'tab-pane active';
      publish.className = '';
      (document.getElementById('publishData') as HTMLElement).className = 'tab-pane';
    }
    if (this.activedFunctionList) {
      this.chooseTab(this.activedFunctionList[0]);
    }
  }

  /**
   * change tab detail is editing or publish data
   */
  changeTab() {
    this.isActiveTabEditing = !this.isActiveTabEditing;
    this.chooseTab(this.currentTab);
  }

  /**
   * get data file publish by tab
   *
   * @param projectId
   * @param typeFile
   */
  handleGetDataTabPublish(projectId: Number, typeFile: string) {
    this.publishInfoService.getPublishInfosByProjectIdAndType(projectId, typeFile).subscribe(
      publishInfoDatas => {
        publishInfoDatas.forEach(publishInfo => {
          let effectiveFromString = moment(publishInfo.effectiveFrom.split('T')[0], moment.ISO_8601);
          publishInfo.effectiveFromString = effectiveFromString.format('MMM. DD, YYYY HH:mm:ss');
        });
        switch (typeFile) {
          case PUBLISH_TYPE.BID:
            this.publishInfoBIDs = publishInfoDatas;
            break;
          case PUBLISH_TYPE.DSC:
            this.publishInfoDSCs = publishInfoDatas;
            break;
          case PUBLISH_TYPE.BSD:
            this.publishInfoBSDs = publishInfoDatas;
            break;
          case PUBLISH_TYPE.GID:
            this.publishInfoGIDs = publishInfoDatas;
            break;
          case PUBLISH_TYPE.EDS:
            // Code here
            break;
          default:
            break;
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        return;
      }
    );
  }

  /**
   * handle get data routes by project id
   *
   * @param projectId id project
   */
  handleGetRoutesByProjectId(projectId: Number) {
    this.routeService.getRoutesForProjectManagerComponentByProjectId(projectId).subscribe(
      routesData => {
        this.routes = routesData.map(routeData => {
          return Helper.convertDataRoute(routeData);
        });
        this.changeDetectorRef.detectChanges();
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        return;
      }
    );
  }

  /**
   * handle get data styles by project id
   *
   * @param projectId id project
   */
  handleGetStylesByProjectId(projectId: Number) {
    this.styleService.getStylesByProjectId(projectId).subscribe(
      styleDatas => {
        this.styles = Helper.convertDataStyles(styleDatas);
        this.changeDetectorRef.detectChanges();
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        return;
      }
    );
  }

  /**
   * handle get data channels by project id
   * @param projectId id project
   */
  handleGetChannelsByProjectId(projectId: Number) {
    this.channelService.getChannelsForProjectManagerComponentByProjectId(projectId).subscribe(channelsData => {
      this.channels = channelsData;
      this.channels.forEach(channel => {
        if (channel.dailySchedulesName.length < 10) {
          let lengthArray = 10 - channel.dailySchedulesName.length;
          for (let index = 0; index < lengthArray; index++) {
            channel.dailySchedulesName.push('');
          }
        }
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * handle get bus stops by project id
   * @param projectId id project
   */
  handleGetBusStopsByProjectId(projectId: Number) {
    this.busStopService.getBusStopsForProjectManagerComponentByProjectId(projectId).subscribe(busStopDatas => {
      this.busStops = Helper.convertDataBusStopsData(busStopDatas);
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * handle when open a project
   */
  handleWhenOpenProject() {
    if (this.projectOpened.hasEds) {
      this.activedFunctionList.push(this.DESTINATION_SIGN_VALUE);
      this.isActiveTabDestination = true;
      this.dataService.sendData([this.CAN_OPEN_DESTINATION_SIGN, true]);
    } else if (this.projectOpened.hasSd) {
      this.activedFunctionList.push(this.SIGNAGE_DISPLAY_VALUE);
      this.isActiveTabSignageDisplay = true;
      this.dataService.sendData([this.CAN_OPEN_SIGNAGE_DISPLAY, true]);
    } else if (this.projectOpened.hasBid) {
      this.activedFunctionList.push(this.ON_BUS_VALUE);
      this.isActiveTabBusInform = true;
      this.dataService.sendData([this.CAN_OPEN_ON_BUS_DISPLAY, true]);
    } else if (this.projectOpened.hasBsd) {
      this.activedFunctionList.push(this.STATION_VALUE);
      this.isActiveTabStation = true;
      this.dataService.sendData([this.CAN_OPEN_STATION, true]);
    } else if (this.projectOpened.hasDsc) {
      this.activedFunctionList.push(this.DIGITAL_SIGNAGE_VALUE);
      this.isActiveTabDigitalSignage = true;
      this.dataService.sendData([this.CAN_OPEN_DIGITAL_SIGNAGE, true]);
    }
  }

  /**
   * reset list active tab = false
   */
  resetActiveTab() {
    this.isActiveTabDestination = false;
    this.isActiveTabBusInform = false;
    this.isActiveTabDigitalSignage = false;
    this.isActiveTabStation = false;
    this.isActiveTabSignageDisplay = false;
  }

  /**
   * select project
   * @param {Project} project selected project
   */
  selectProject(project: Project) {
    this.projectSelected = project;
    this.routeSelected = undefined;
    this.channelSelected = undefined;
    this.styleSelected = undefined;
    this.busStopSelected = undefined;
  }

  /**
   * Add new Project
   */
  public addProject() {
    if (this.isAddNew) {
      return;
    }
    let newProject: Project = new Project();
    newProject.isPublic = false;
    newProject.hasBid = false;
    newProject.hasDsc = false;
    newProject.hasEds = false;
    newProject.hasBsd = false;
    newProject.hasSd = false;
    this.isAddNew = true;
    this.isChangedData = true;
    this.dialogService.showDialog(DialogProjectManagerComponent, { data: { project: newProject, projects: this.projects } }, result => {
      this.isChangedData = false;
      if (result) {
        this.projects.push(result);
        this.selectProject(this.projects[this.projects.length - 1]);
        this.projectOpened = undefined;
        // reset active tab = false
        this.resetActiveTab();
        this.dataService.sendData([this.ADD_VALUE, result]);
      }
      this.isAddNew = false;
    });
  }

  /**
   * Edit Project
   */
  public editProject() {
    if (this.projects.length === 0) {
      return;
    }
    this.isChangedData = true;
    if (this.projectSelected) {
      this.dialogService.showDialog(
        DialogProjectManagerComponent,
        {
          data: {
            project: Object.assign({}, this.projectSelected),
            projects: this.projects
          }
        },
        editedProject => {
          this.isChangedData = false;
          if (editedProject) {
            let selectedIndex = this.projects.findIndex(project => project.id == this.projectSelected.id);
            this.projects[selectedIndex] = editedProject;
            this.dataService.sendData([this.EDIT_VALUE, editedProject]);
            if (editedProject.id == this.commonObject.projectId) {
              this.dataService.sendData([this.PROJECT_NAME, editedProject.name]);
              this.commonObject.projectName = editedProject.name;
              this.store.dispatch(
                new SaveMainStateAction({
                  common: this.commonObject
                })
              );
            }
            this.selectProject(editedProject);
            this.projectOpened = undefined;
            // reset active tab = false
            this.resetActiveTab();
          }
        }
      );
    } else {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select project.' } });
      return;
    }
  }

  /**
   * delete selected project or publish file
   */
  delete() {
    if (this.isActiveTabBusInform && this.publishInfoBIDs?.findIndex(data => data.isChecked) >= 0) {
      this.deletePublishFile(this.publishInfoBIDs, PUBLISH_TYPE.BID);
    } else if (this.isActiveTabDigitalSignage && this.publishInfoDSCs?.findIndex(data => data.isChecked) >= 0) {
      this.deletePublishFile(this.publishInfoDSCs, PUBLISH_TYPE.DSC);
    } else if (this.isActiveTabStation && this.publishInfoBSDs?.findIndex(data => data.isChecked) >= 0) {
      this.deletePublishFile(this.publishInfoBSDs, PUBLISH_TYPE.BSD);
    } else if (this.isActiveTabSignageDisplay && this.publishInfoGIDs?.findIndex(data => data.isChecked) >= 0) {
      this.deletePublishFile(this.publishInfoGIDs, PUBLISH_TYPE.GID);
    } else if (this.projects.length === 0 || !this.projectSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select project.' } });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete project ${this.projectSelected.name}?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.deleteProject();
      }
    );
  }

  /**
   * delete project
   */
  private deleteProject(): void {
    this.projectService.deleteProject(this.projectSelected.id).subscribe(
      () => {
        this.projects = this.projects.filter(project => project.id !== this.projectSelected.id);
        this.projectSelected = this.projects[0];
        this.commonObject.projectId = undefined;
        this.commonObject.projectName = undefined;
        this.dataService.sendData([this.PROJECT_NAME, undefined]);
        this.store.dispatch(
          new SaveMainStateAction({
            common: this.commonObject
          })
        );
        if (this.isShowDetail) {
          this.isShowDetail = false;
        }
        this.dataService.resetLayout(true);
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * delete publish file
   * @param publishInfos
   */
  deletePublishFile(publishInfos: Array<PublishInfo>, typeFile: string) {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.CONFIRM_DELETE_PUBLISH_FILE,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (!result) {
          return;
        }
        let listPublishFileDelete = publishInfos.filter(data => data.isChecked);
        let listIdDelete = new Array();
        listPublishFileDelete.forEach(data => {
          listIdDelete.push(data.id);
        });
        this.publishInfoService.deleteListPublishInfo(listIdDelete).subscribe(() => {
          switch (typeFile) {
            case PUBLISH_TYPE.BID:
              this.publishInfoBIDs = publishInfos.filter(data => !data.isChecked);
              break;
            case PUBLISH_TYPE.DSC:
              this.publishInfoDSCs = publishInfos.filter(data => !data.isChecked);
              break;
            case PUBLISH_TYPE.BSD:
              this.publishInfoBSDs = publishInfos.filter(data => !data.isChecked);
              break;
            case PUBLISH_TYPE.GID:
              this.publishInfoGIDs = publishInfos.filter(data => !data.isChecked);
              break;
            case PUBLISH_TYPE.EDS:
              // Code here
              break;
            default:
              break;
          }
          this.checkCheckAll();
          this.toast.success(this.MESSAGE_DELETE_SUCCESS, '');
        });
      }
    );
  }

  /**
   * Handle duplicate project
   */
  duplicateProject() {
    this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Warning', text: 'Undeveloped function.' } });
    return;
  }

  /**
   * Get list Project to display
   */
  fetchDataProjects() {
    let userCurrentId: Number = +this.commonObject.userId;
    const isRoot = this.commonObject.userIdString == Constant.ROOT;
    this.projectService.getProjectsByUserId(userCurrentId, isRoot).subscribe(
      projectData => {
        this.projects = projectData.map(projectData => {
          return Helper.convertDataProject(projectData);
        });
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * select route
   * @param route Route object
   */
  selectRoute(route: Route) {
    this.routeSelected = route;
    this.dataService.sendData(['project-to-main', route]);
  }

  /**
   * select style
   * @param style Style object
   */
  selectStyle(style: Style) {
    this.styleSelected = style;
    this.dataService.sendData(['project-to-main', style]);
  }

  /**
   * select bus stop
   * @param busStop BusStop object
   */
  selectBusStop(busStop: BusStop) {
    this.busStopSelected = busStop;
  }

  /**
   * select channel
   * @param channel Channel object
   */
  selectChannel(channel: Channel) {
    this.channelSelected = channel;
    this.dataService.sendData(['project-digital-to-main', channel]);
  }

  /**
   * choose tab
   * @param value (On-bus, Destination-Sign, Digital-Signage, Station, Signage-Display)
   */
  chooseTab(value: string) {
    this.currentTab = value;
    switch (value) {
      case this.ON_BUS_VALUE:
        this.isActiveTabBusInform = true;
        this.isActiveTabDestination = false;
        this.isActiveTabDigitalSignage = false;
        this.isActiveTabStation = false;
        this.isActiveTabSignageDisplay = false;
        if (!this.isActiveTabEditing) {
          this.handleGetDataTabPublish(this.projectOpened.id, PUBLISH_TYPE.BID);
        } else if (this.projectOpened.hasBid) {
          this.handleGetRoutesByProjectId(this.projectOpened.id);
        }
        this.dataService.sendData([this.CAN_OPEN_ON_BUS_DISPLAY, true]);
        break;
      case this.DESTINATION_SIGN_VALUE:
        this.isActiveTabDestination = true;
        this.isActiveTabBusInform = false;
        this.isActiveTabDigitalSignage = false;
        this.isActiveTabStation = false;
        this.isActiveTabSignageDisplay = false;
        if (!this.isActiveTabEditing) {
          this.handleGetDataTabPublish(this.projectOpened.id, PUBLISH_TYPE.EDS);
        } else if (this.projectOpened.hasEds) {
          //code
        }
        this.dataService.sendData([this.CAN_OPEN_DESTINATION_SIGN, true]);
        break;
      case this.DIGITAL_SIGNAGE_VALUE:
        this.isActiveTabDigitalSignage = true;
        this.isActiveTabDestination = false;
        this.isActiveTabBusInform = false;
        this.isActiveTabStation = false;
        this.isActiveTabSignageDisplay = false;
        if (!this.isActiveTabEditing) {
          this.handleGetDataTabPublish(this.projectOpened.id, PUBLISH_TYPE.DSC);
        } else if (this.projectOpened.hasDsc) {
          this.handleGetChannelsByProjectId(this.projectOpened.id);
        }
        this.dataService.sendData([this.CAN_OPEN_DIGITAL_SIGNAGE, true]);
        break;
      case this.STATION_VALUE:
        this.isActiveTabStation = true;
        this.isActiveTabDigitalSignage = false;
        this.isActiveTabDestination = false;
        this.isActiveTabBusInform = false;
        this.isActiveTabSignageDisplay = false;
        if (!this.isActiveTabEditing) {
          this.handleGetDataTabPublish(this.projectOpened.id, PUBLISH_TYPE.BSD);
        } else if (this.projectOpened.hasBsd) {
          this.handleGetBusStopsByProjectId(this.projectOpened.id);
        }
        this.dataService.sendData([this.CAN_OPEN_STATION, true]);
        break;
      case this.SIGNAGE_DISPLAY_VALUE:
        this.isActiveTabSignageDisplay = true;
        this.isActiveTabDigitalSignage = false;
        this.isActiveTabDestination = false;
        this.isActiveTabBusInform = false;
        this.isActiveTabStation = false;
        if (!this.isActiveTabEditing) {
          this.handleGetDataTabPublish(this.projectOpened.id, PUBLISH_TYPE.GID);
        } else if (this.projectOpened.hasSd) {
          this.handleGetStylesByProjectId(this.projectOpened.id);
        }
        this.dataService.sendData([this.CAN_OPEN_SIGNAGE_DISPLAY, true]);
        break;
      default:
        break;
    }
    this.checkCheckAll();
  }

  /**
   * Drag folder
   * @param e event
   */
  dragFolder(e, data: any) {
    var obj = JSON.stringify(data);
    e.dataTransfer.setData('data', obj);
  }

  /**
   * check all file
   */
  checkAll() {
    this.isCheckAll = !this.isCheckAll;
    if (this.isActiveTabBusInform) {
      this.handleCheckedCheckbox(this.publishInfoBIDs);
    } else if (this.isActiveTabDigitalSignage) {
      this.handleCheckedCheckbox(this.publishInfoDSCs);
    } else if (this.isActiveTabStation) {
      this.handleCheckedCheckbox(this.publishInfoBSDs);
    } else if (this.isActiveTabSignageDisplay) {
      this.handleCheckedCheckbox(this.publishInfoGIDs);
    }
  }

  /**
   * handle checked checkbox
   * @param publishInfos
   */
  handleCheckedCheckbox(publishInfos: Array<PublishInfo>) {
    publishInfos.forEach(data => (data.isChecked = this.isCheckAll));
  }

  /**
   * check file
   * @param publishfile
   */
  changeChecked(publishFile: PublishInfo) {
    publishFile.isChecked = !publishFile.isChecked;
    this.checkCheckAll();
  }

  /**
   * check check all file
   */
  checkCheckAll() {
    if (this.isActiveTabBusInform) {
      if (this.publishInfoBIDs.length == 0) {
        this.isCheckAll = false;
        return;
      }
      this.isCheckAll = this.publishInfoBIDs.every(data => data.isChecked);
    } else if (this.isActiveTabDigitalSignage) {
      if (this.publishInfoDSCs.length == 0) {
        this.isCheckAll = false;
        return;
      }
      this.isCheckAll = this.publishInfoDSCs.every(data => data.isChecked);
    } else if (this.isActiveTabStation) {
      if (this.publishInfoBSDs.length == 0) {
        this.isCheckAll = false;
        return;
      }
      this.isCheckAll = this.publishInfoBSDs.every(data => data.isChecked);
    } else if (this.isActiveTabSignageDisplay) {
      if (this.publishInfoGIDs.length == 0) {
        this.isCheckAll = false;
        return;
      }
      this.isCheckAll = this.publishInfoGIDs.every(data => data.isChecked);
    }
  }
}
