import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BackgroundColorModeEnum } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { NoticeText } from 'app/model/entity/schedule-operation-manager/notification-registration/NoticeText';
import { CommonService } from 'app/service/common.service';
import _ from 'lodash';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { DialogService } from './../../../service/dialog.service';

@Component({
  selector: 'quill',
  templateUrl: './quill.component.html',
  styleUrls: ['./quill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuillComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() noticeText: string;
  @Input() backgroundColor: any;
  @Input() isEdit: boolean;
  @Output() setDelta: EventEmitter<string> = new EventEmitter();
  @Output() contentSaved: EventEmitter<any> = new EventEmitter();
  @Output() unsaveText: EventEmitter<any> = new EventEmitter();
  quillEditor: any;
  noticeTextData: NoticeText;
  BackgroundColorModeEnum = BackgroundColorModeEnum;
  subscriptions: Array<Subscription> = new Array<Subscription>();
  public languageKey: string; //language key
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService
  ) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
      })
    );
  }

  ngOnInit(): void {
    this.languageKey = this.commonService.getCommonObject().setting?.language;
  }

  ngAfterViewInit(): void {
    this.quillEditor = new Quill('#editor', {
      modules: {
        toolbar: [['bold', { color: [] }]],
        keyboard: {
          bindings: {
            enter: {
              key: 13,
              handler: () => false
            },
            'shift+enter': {
              key: 13,
              shiftKey: true,
              handler: () => false
            }
          }
        }
      },
      theme: 'snow',
      formats: ['bold', 'color'] // Chỉ định các định dạng được phép
    });

    this.quillEditor.enable(this.isEdit);

    // Prevent Enter key
    this.quillEditor.root.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    });

    // Handle paste event
    this.quillEditor.clipboard.addMatcher('*', (node, delta) => {
      delta.ops.forEach(op => {
        if (typeof op.insert === 'string') {
          op.insert = op.insert.replace(/[\r\n]+/g, ' ');
        }
      });
      setTimeout(() => {
        this.setDelta.emit(JSON.stringify(this.quillEditor.getContents()));
      }, 0);
      return delta;
    });

    // set giá trị delta sau khi người dùng đã hoàn thành tương tác
    this.quillEditor.on('selection-change', range => {
      if (!range) {
        this.setDelta.emit(JSON.stringify(this.quillEditor.getContents()));
      }
    });

    this.updateNoticeTextData();
    this.toggleBackground(this.backgroundColor);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.backgroundColor && !changes.backgroundColor.isFirstChange()) {
      this.toggleBackground(this.backgroundColor);
    }
    if (changes.noticeText && !changes.noticeText.isFirstChange()) {
      this.updateNoticeTextData();
    }
    if (changes.isEdit && !changes.isEdit.isFirstChange()) {
      this.quillEditor.enable(this.isEdit);
      this.changeDetectorRef.detectChanges();
    }
  }

  saveText(isChange?: boolean) {
    if (!this.validateNoticeText()) {
      return;
    }
    const delta = this.quillEditor.getContents();
    this.noticeTextData.text = _.cloneDeep(this.quillEditor?.getText());
    this.noticeTextData.delta = _.cloneDeep(JSON.stringify(delta));
    this.noticeTextData.parsedDelta = _.cloneDeep(this.parseDelta(delta));
    if (isChange) {
      return;
    }
    this.contentSaved.emit(this.noticeTextData);
  }

  parseDelta(delta: any) {
    const ops = delta.ops;
    let output = '';

    ops.forEach((op: any) => {
      let prefix = '';
      let postfix = '';

      if ('attributes' in op) {
        if ('bold' in op.attributes) {
          prefix = '<b>';
          postfix = '</b>';
        }
        if ('color' in op.attributes) {
          prefix += `<span style="color: ${op.attributes.color};">`;
          postfix += '</span>';
        }
      }
      output += prefix + op.insert + postfix;
    });

    return output;
  }

  toggleBackground(backgroundColor: string): void {
    const quillElement = document.querySelector('.quill');
    const editorElement = document.querySelector('.ql-editor');
    const boldButton = document.querySelector('.ql-bold');
    const colorButton = document.querySelector('.ql-color');

    const isLightMode = backgroundColor == BackgroundColorModeEnum.LIGHT;
    // Xác định lớp cần thêm và xóa
    const addClass = isLightMode ? 'white-background' : 'gray-background';
    const removeClass = isLightMode ? 'gray-background' : 'white-background';

    // Thêm lớp cho quillElement và editorElement
    quillElement.classList.add(addClass);
    quillElement.classList.remove(removeClass);

    editorElement.classList.add(addClass);
    editorElement.classList.remove(removeClass);

    // Cập nhật lớp cho nút Bold (B)
    if (boldButton) {
      boldButton.classList.add(isLightMode ? 'ql-light' : 'ql-dark');
      boldButton.classList.remove(isLightMode ? 'ql-dark' : 'ql-light');
    }

    // Cập nhật lớp cho nút Color (A)
    if (colorButton) {
      colorButton.classList.add(isLightMode ? 'ql-light' : 'ql-dark');
      colorButton.classList.remove(isLightMode ? 'ql-dark' : 'ql-light');
    }
  }

  validateNoticeText(): boolean {
    if (!this.noticeTextData || !this.noticeTextData?.label || !this.noticeTextData?.label.length || this.noticeTextData.label.length > 8) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.maximum-label-length')
        }
      });
      return false;
    }
    if (!this.getQuillTextAndRemoveLineBreaks()?.length || this.getQuillTextAndRemoveLineBreaks()?.length > 256) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-operation-manager.tab-notification-registration.msg.maximum-text-length')
        }
      });
      return false;
    }
    return true;
  }

  updateNoticeTextData(): void {
    if (this.noticeText && this.noticeText.length) {
      this.noticeTextData = JSON.parse(this.noticeText);
      if (this.noticeTextData?.delta && this.noticeTextData.delta?.length) {
        this.quillEditor.setContents(JSON.parse(this.noticeTextData?.delta));
      } else {
        this.quillEditor.setContents([]);
      }
    } else {
      this.quillEditor.setContents([]);
    }
  }

  getQuillTextAndRemoveLineBreaks(): string {
    let text = this.quillEditor?.getText() || '';
    return (text = text.endsWith('\n') ? text.slice(0, -1) : text);
  }

  focusEditor(): void {
    const length = this.quillEditor.getLength();
    this.quillEditor.focus();
    this.quillEditor.setSelection(length, length);
    this.changeDetectorRef.detectChanges();
  }

  cancel(): void {
    this.unsaveText.emit(this.noticeTextData?.id);
  }
}
